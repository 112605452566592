import { Button } from '@chakra-ui/button';
import {
	AspectRatio, Box, Flex 
} from '@chakra-ui/layout';
import NextImage from 'next/image';
import { useEffect, useState } from 'react';
import YouTube from 'react-youtube';

type MediaPlayerProps = {
	sourceUuid: string;
	ratio?: number;
}

const MediaPlayer: React.FC<MediaPlayerProps> = ({ sourceUuid, ratio = 16/9 }) => {
	const [mounted, setMounted] = useState(false);
	const [started, setStarted] = useState(false);
	const [player, setPlayer] = useState(null);
	
	const posterSrc = `http://img.youtube.com/vi/${sourceUuid}/0.jpg`;
	
	useEffect(() => {
		setMounted(true);
	}, [mounted]);

	const onStartVideo = () => {
		setStarted(true);
	};

	const onReadyVideo = (event) => {
		setPlayer(event.target);
		event.target.playVideo();
	};

	const onPlayVideo = () => {
		player.playVideo();
	};

	const onPauseVideo = () => {
		player.pauseVideo();
	};

	return (
		<Box pos="relative">
			{!started && <>
				<Poster
					src={posterSrc}
					ratio={ratio}
				/>
				<Box
					pos="absolute"
					bottom={2}
					right={2}
				>
					<Button
						colorScheme="green"
						onClick={onStartVideo}
						size="sm"
					>
						Play video
					</Button>

				</Box>
			</>}

			{started && <><Player
				ratio={ratio}
				videoId={sourceUuid}
				onReady={onReadyVideo}
			/>
			
			<Flex display="block">
				<Button
					onClick={onPlayVideo}
					size="sm"
					disabled={mounted && player === null}
				>
				Play
				</Button>
				<Button
					onClick={onPauseVideo}
					size="sm"
					disabled={mounted && player === null}
				>
				Pause
				</Button>
			</Flex></>}
		</Box>
	);
};

const Poster = ({ src, ratio }) => <AspectRatio
	ratio={ratio}
	backgroundColor="gray.100"
>
	<NextImage
		src={src}
		layout="fill"
		objectFit="cover"
	></NextImage>
</AspectRatio>;

const Player = ({
	ratio, videoId, onReady 
}) => <AspectRatio
	ratio={ratio}
	backgroundColor="gray.100"
>
	<YouTube
		opts={{
			width: '100%',
			height: '100%',
			playerVars: {
				autoplay: 0,
				controls: 1,
				showinfo: 0,
				fs: 0,
				modestbranding: 1,
			}
		}}
		videoId={videoId}
		onReady={onReady}
	/>
</AspectRatio>;

export default MediaPlayer;
