import {
	Box, Button, Flex, Heading, HStack, Text
} from '@chakra-ui/react';
import { useSession } from 'next-auth/client';
import { Media } from '../../prisma/utils/media';
import TagCloud from '../tag/cloud';
import MediaPlayer from './player';

type MediaPreviewProps = {
	media: Media;
}

const MediaPreview: React.FC<MediaPreviewProps> = ({ media }) => {
	const { sourceUuid } = media;
	// const sourceUuid = 'r7xsYgTeM2Q';
	// https://www.youtube.com/oembed?url=https://www.youtube.com/watch?v=UEEsdXn8oG8&format=json
	const [ session, loading ] = useSession();

	return (
		<Box>
			<Heading size="md">{media.sourceTitle}</Heading>
			<Flex justifyContent="space-between">
				<Text>{media.sourceAuthor}</Text>
				<Text>{media.type.name}</Text>
				<Text>{media.level}</Text>
				{/* <Text>Likes {media._count.interactions}</Text> */}
				{/* <Text>Playlists {media._count.playlists}</Text> */}
			</Flex>
			<MediaPlayer sourceUuid={sourceUuid} />
			<HStack
				as="nav"
				p={2}
				backgroundColor="green.100"
			>
				<Button
					disabled={!session}
					colorScheme="pink"
					size="sm"
				>
					Love
				</Button>
				<Button
					disabled={!session}
					colorScheme="pink"
					size="sm"
				>
					Favorite
				</Button>
				<Button
					disabled={!session}
					colorScheme="pink"
					size="sm"
				>
					Add to playlist
				</Button>
			</HStack>
			<Box>
				<Text>Categories</Text>
				<TagCloud
					tags={media.categories}
					hrefPrefix="/media/filter/categories"
					colorScheme="blue"
				/>
			</Box>
			<Box>
				<Text>Tags</Text>
				<TagCloud
					tags={media.tags}
					hrefPrefix="/media/filter/tags"
					colorScheme="pink"
				/>
			</Box>
		</Box>
	);
};

export default MediaPreview;
