import React from 'react';
import {
	Divider, HStack, Link 
} from '@chakra-ui/react';

const Navigation: React.FC = () => {
	return (
		<HStack>
			<Link href="/playlists">Playlists</Link>
			<Divider></Divider>
			<Link href="/videos">Videos</Link>
		</HStack>
	);
};

export default React.memo(Navigation);
