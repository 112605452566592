import {
	Box, Flex, Heading 
} from '@chakra-ui/layout';
import Head from 'next/head';
import Navigation from '../navigation';
import ProfileNavigation from '../profile/navigation';

type LandingPageProps = {
	title: string;
	description: string;
}

const LandingPage: React.FC<LandingPageProps> = ({
	children, title, description 
}) => {
	return (
		<>
			<Head>
				<title>{title}</title>
				<meta
					name="description"
					content={description}
				/>
				<meta
					name="author"
					content="Status Code, https://statuscode.nl, martijn@statuscode.nl"
				/>
				<meta
					name="viewport"
					content="width=device-width, initial-scale=1, shrink-to-fit=no"
				/>
				<link
					rel="icon"
					href="/favicon-32x32.png"
				></link>
			</Head>
			<Box
				as="header"
				background="pink.200"
				padding="4"
			>
				<Flex
					justifyContent="space-between"
					alignItems="baseline"
				>
					<Heading><a href="/">Yoga!</a></Heading>
					<Navigation></Navigation>
					<ProfileNavigation></ProfileNavigation>
				</Flex>
			</Box>
			<Box
				as="main"
				padding="4"
			>
				{children}
			</Box>
			<Box as="footer">

			</Box>
		</>
	);
};

export default LandingPage;
