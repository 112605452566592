import { Media } from '@/prisma/utils/media';
import { MediaType } from '@/prisma/utils/media-type';
import { PlaylistWithItems } from '@/prisma/utils/playlist';
import { ClientSafeUser } from '@/prisma/utils/user';
import { Prisma } from '@prisma/client';
import {
	useMutation, useQuery, useQueryClient
} from 'react-query';

async function request(uri, options = {}) {
	const response = await fetch(uri, options);

	if (!response.ok) {
		let error;

		try {
			error = await response.json();			
		} catch (parseError) {
			console.error(parseError);

			throw parseError;
		}

		throw error;
	}

	return response;
}

export function useRecentMedia() {
	return useQuery<Media[], Error>(
		['media', { recent: true }],
		async () => (
			await request('/api/media?take=12&order=latest')
		).json()
	);
}

export function usePopularMedia() {
	return useQuery<Media[], Error>(
		['media', { popular: true }],
		async () => (
			await request('/api/media?take=12&order=popular')
		).json()
	);
}

export function useRecentPlaylists() {
	return useQuery<PlaylistWithItems[], Error>(
		['playlists', { recent: true }],
		async () => (
			await request('/api/playlists?take=12&order=latest')
		).json()
	);
}

export function usePopularPlaylists() {
	return useQuery<PlaylistWithItems[], Error>(
		['playlists', { popular: true }],
		async () => (
			await request('/api/playlists?take=12&order=popular')
		).json()
	);
}

export function useUserPlaylists(user: ClientSafeUser) {
	return useQuery<PlaylistWithItems[], Error>(
		['playlists', { user: user }],
		async () => (await request(`/api/users/${user.id}/playlists`)).json()
	);
}

export function usePlaylist(id: number) {
	return useQuery<PlaylistWithItems, Error>(
		['playlists', id],
		async () => (await request(`/api/playlists/${id}`)).json()
	);
}

export function useMediaTypes() {
	return useQuery<MediaType[], Error>(
		['mediaTypes'],
		async () => (await request('/api/media-types')).json()
	);
}

const defaultHeaders = {
	'Content-Type': 'application/json',
};
const useMutationOptions = () => {
	const queryClient = useQueryClient();

	return {
		onMutate: variables => {
			// A mutation is about to happen!
			console.log('onMutate', variables);
			
			// Optionally return a context containing data to use when for example rolling back
			// return { id: 1 };
		},
		onError: (error, variables, context) => {
			console.log('onError', error, variables, context);
		},
		onSuccess: async (response, variables, context) => {
			console.log('onSuccess', response, variables, context);
			// const playlist = await response.json();
			queryClient.invalidateQueries('playlists');
			// queryClient.invalidateQueries(['playlists', playlist.id]);
		},
		onSettled: (data, error, variables, context) => {
			// Error or success... doesn't matter!
			console.log('onSettled');
		},
	};
};

export function useCreatePlaylist() {
	const options = useMutationOptions();

	return useMutation((newPlaylist: Prisma.PlaylistCreateWithoutOwnerInput) => request(
		'/api/playlists', {
			method: 'POST',
			headers: defaultHeaders,
			body: JSON.stringify(newPlaylist),
		}
	), options);
}

export function useUpdatePlaylist() {
	const options = useMutationOptions();

	return useMutation((playlist: Prisma.PlaylistUncheckedUpdateInput) => request(
		`/api/playlists/${playlist.id}`, {
			method: 'PUT',
			headers: defaultHeaders,
			body: JSON.stringify(playlist),
		}
	), options);
}

export function useDeletePlaylist() {
	const options = useMutationOptions();

	return useMutation((playlist: Prisma.PlaylistWhereUniqueInput) => request(
		`/api/playlists/${playlist.id}`, {
			method: 'DELETE',
			headers: defaultHeaders,
		}
	), options);
}

export function useUpdatePlaylistItems() {
	const options = useMutationOptions();

	return useMutation(({ playlist, items }: { playlist: Prisma.PlaylistUncheckedUpdateInput, items: Prisma.PlaylistItemUncheckedUpdateInput[] }) => request(
		`/api/playlists/${playlist.id}/items`, {
			method: 'PUT',
			headers: defaultHeaders,
			body: JSON.stringify({
				items: items.map(i => i.id),
			})
		}
	), options);
}

export function useDeletePlaylistItems() {
	const options = useMutationOptions();

	return useMutation(({ playlist, items }: { playlist: Prisma.PlaylistUncheckedUpdateInput, items: Prisma.PlaylistItemUncheckedUpdateInput[] }) => request(
		`/api/playlists/${playlist.id}/items`, {
			method: 'DELETE',
			headers: defaultHeaders,
			body: JSON.stringify({
				items: items.map(i => i.id),
			})
		}
	), options);
}

export function useCreateMedia() {
	const options = useMutationOptions();

	return useMutation((newMedia: Prisma.MediaCreateWithoutOwnerInput) => request(
		'/api/media', {
			method: 'POST',
			headers: defaultHeaders,
			body: JSON.stringify(newMedia),
		}
	), options);
}

export function useUpdateMedia() {
	const options = useMutationOptions();

	return useMutation((media: Prisma.MediaUncheckedUpdateInput) => request(
		`/api/media/${media.id}`, {
			method: 'PUT',
			headers: defaultHeaders,
			body: JSON.stringify(media),
		}
	), options);
}
