import { ClientSafeUser } from '@/prisma/utils/user';
import { Avatar } from '@chakra-ui/avatar';
import {
	Box, Flex, Link, Text 
} from '@chakra-ui/layout';
import React from 'react';

type UserCardProps = {
	user: ClientSafeUser;
}

const UserCard: React.FC<UserCardProps> = ({ user }) => {
	return (
		<Box>
			<Flex alignItems="center">
				<Link>
					<Avatar
						src={user.image}
						alt={user.name}
						mr="2"
					/>
				</Link>
				<Link href={`users/${user.id}/playlists`}>
					<Text>{user.name}</Text>
				</Link>
			</Flex>
			<Box>
				
			</Box>
		</Box>
	);
};

export default UserCard;
